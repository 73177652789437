.tableContainer {
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  overflow-x: auto; /* Allows horizontal scrolling for large tables */
}

h1 {
  margin-bottom: 20px;
  font-size: 28px; /* Increase header size */
  color: #333;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 16px; /* Increase padding for better readability */
  text-align: left;
}

th {
  background-color: #f4f4f4;
  color: #333;
  font-size: 16px; /* Increase font size for header */
}



tr:hover {
  background-color: #f1f1f1;
}

.actionButton {
  padding: 10px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px; /* Increase font size for better visibility */
}

.actionButton:hover {
  background-color: #0056b3;
}

.loadingText, .noSurveysText {
  margin: 20px;
  font-size: 18px; /* Increase font size for better visibility */
  color: #666;
}
