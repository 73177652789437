.container {
    max-width: 600px;
    /* margin: 0 auto; */
    padding: 20px;
  }  
  
  form {
    display: flex;
    flex-direction: column;
margin-bottom: 20px;    
  }
  
  .companyForm {
 
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  input {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-top: 10px;
  }
  
  button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  button + button {
    margin-left: 10px;
  }
  

  button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
    cursor: pointer;
    margin-left: 1px;
    margin-top: 4px
}