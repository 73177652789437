.navbar {
  position: fixed !important;
  top: 0;
  width: 100%;
  height: 400px;
}
/* Navbar.css */
.navbar {
  overflow: hidden;
  height: 100vh;
  width: 100%;
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu-left {
  height: 60px;
  right: auto;
  left: 50px;
  transform: translateX(-30%);
}
.dropdown-menu {
  height: 200px;
}

.navbar-nav .dropdown-menu.show {
  display: block;
}

.set-data h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: red;
}

body {
  width: 100vw;
  padding-top: 60px;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("https://coolbackgrounds.io/images/backgrounds/index/ranger-4df6c1b6.png");
  overflow: auto;
}

.circleIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f8f9fa;
  border: 2px solid black;
  font-size: 20px;
  color: #333;
  text-align: center;
  line-height: 40px;
  margin-right: 20px;
}
